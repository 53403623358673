export default [
    {
      key: "client_name",
      label: "Client",
    },
    {
      key: "type_send",
      label: "Type",
    },
    {
      key: "title",
      thClass: "text-center",
    },
    {
      key: "sumeq",
      label: "Bureaus",
    },
    {
      key: "date",
      label: "Date of CR",
      tdClass: "pt-2",
    },
    {
      key: "program",
      label: "Program",
      tdClass: "pt-2",
    },
    {
      key: "advisor_name",
      label: "In Charge Of",
      tdClass: "pt-2",
    },
    {
      key: "administrator",
      tdClass: "pt-2",
    },
    {
      key: "cr_date",
      label: "Creation Date",
      tdClass: "pt-2",
    },
    {
      key: "status",
      tdClass: "pt-2",
      thClass: "text-center",
    },
    {
      key: "file",
      tdClass: "pt-2",

    },
    {
      key: "tracking",
      tdClass: "pt-2",
      thClass: "text-center",
    },

    {
      key: "actions",
      thClass: "text-center",
      tdClass: "pt-1",
    },
  ]
  