<template>
  <b-card no-body class="mb-0 border-0">
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="filters"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @onChangeCurrentPage="onChangeCurrentPage"
      @reload="$refs[`refLetters${typeTab}`].refresh()"
    >
      <!-- @reload="getPendingPayments"
      @onChangeCurrentPage="onChangeCurrentPage" -->
      <template #table>
        <b-table
          slot="table"
          :ref="`refLetters${typeTab}`"
          small
          :items="myProvider"
          :fields="tabFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client_name)="data">
            <div class="d-flex align-items-center">
              <!-- <icon-status
                :client-status="data.item.client_status"
                :state-hold="data.item.state_hold"
              >
              </icon-status> -->
              <status-account
                :account="data.item"
                :text="false"
              ></status-account>

              <div>
                <router-link
                  target="_blank"
                  :to="{
                    name: 'administration-dashboard',
                    params: { idClient: `${data.item.client_account_id}` },
                  }"
                >
                  <p class="m-0">{{ data.item.client_name }}</p>
                </router-link>
                <p class="m-0">
                  {{ data.item.account }}
                </p>
                <p class="m-0" v-if="data.item.is_digital == 0">Regular</p>
                <p v-else>Digital</p>
              </div>
            </div>
          </template>
          <template #cell(advisor_name)="data">
            <span>
              {{ data.item.is_digital == 1 ? "Agent:" : "Advisor:" }}</span
            >
            <span> {{ data.item.advisor_name }} </span>
          </template>
          <template #cell(type_send)="data">
            <div class="mt-1">
              <b-badge
                :variant="data.item.t_send == 1 ? 'success' : 'warning'"
                >{{ data.item.type_send }}</b-badge
              >
            </div>
          </template>
          <template #cell(title)="data">
            <div v-if="typeTab != 4">
              <b-button
                variant="outline"
                class="m-0 pt-1 text-primary"
                @click="openModalEditFirtRound(data.item)"
                >{{ data.item.title }}
              </b-button>
            </div>
            <div v-else>
              <span class="m-0 mt-1"> {{ data.item.title }} </span>
            </div>
          </template>
          <template #cell(sumeq)="data">
            <div>
              <b-list-group flush>
                <b-list-group-item
                  v-if="data.item.sumtr > 0"
                  class="d-flex justify-content-between align-items-center p-0"
                  style="font-size: 10px"
                >
                  TUR
                  <b-badge pill style="font-size: 8px">{{
                    data.item.sumtr
                  }}</b-badge>
                </b-list-group-item>

                <b-list-group-item
                  v-if="data.item.sumex > 0"
                  class="d-flex justify-content-between align-items-center p-0"
                  style="font-size: 10px"
                >
                  EXP
                  <b-badge pill style="font-size: 8px">{{
                    data.item.sumex
                  }}</b-badge>
                </b-list-group-item>

                <b-list-group-item
                  v-if="data.item.sumeq > 0"
                  class="d-flex justify-content-between align-items-center p-0"
                  style="font-size: 10px"
                >
                  EQR
                  <b-badge pill style="font-size: 8px">{{
                    data.item.sumeq
                  }}</b-badge>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>
          <template #cell(file)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="FolderIcon"
                size="18"
                class="cursor-pointer"
                v-b-tooltip.hover.top="'Files'"
                @click="openModalFiles(data.item)"
              />
            </div>
          </template>
          <template #cell(status)="data">
            <div class="d-flex justify-content-center">
              <b-badge href="#" :variant="statusColor(data.item.state)">{{
                data.item.status
              }}</b-badge>
            </div>
          </template>

          <template #cell(tracking)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="ListIcon"
                size="15"
                :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                class="mr-50 cursor-pointer"
                v-b-tooltip.hover.top="'Tracking'"
                @click="openModalTracking(data.item)"
              />
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <div>
                <b-button
                  variant="outline"
                  @click="openModalProcess(data.item, 5)"
                >
                  <feather-icon
                    class="text-primary"
                    icon="SettingsIcon"
                    v-b-tooltip.hover.top="'Process'"
                  />
                </b-button>
              </div>

              <div class="text-center">
                <b-button variant="outline" @click="openModalReturn(data.item)">
                  <feather-icon
                    class="text-danger"
                    icon="CornerDownLeftIcon"
                    v-b-tooltip.hover.top="'Return'"
                  />
                </b-button>
              </div>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-files
      v-if="modalFileStatus"
      :data-file="dataFile"
      @hideModal="modalFileStatus = false"
    />
    <modal-details
      v-if="modalEditFirtRoundState"
      :details-round-data="dataEditFirtRound"
      :validate-modal="1"
      @close="modalEditFirtRoundState = false"
    />
    <modal-tracking
      v-if="modalTrackingState"
      :data-tracking="dataTracking"
      @hideModal="modalTrackingState = false"
    />
    <modal-process
      v-if="modalProcessState"
      :dataClient="dataProcess"
      :type="type"
      @updateTable="$refs[`refLetters${typeTab}`].refresh()"
      @hideModal="modalProcessState = false"
    />
    <modal-return
      v-if="modalReturnState"
      :data-client="dataReturn"
      @hideModal="modalReturnState = false"
      @updateTable="$refs[`refLetters${typeTab}`].refresh()"
    />
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import fields from "./data/letters.fields.js";
import filters from "./data/letters.filters.js";
// Componest
import LettersService from "@/views/administration/views/letters/services/letters.service.js";
import IconStatus from "@/views/commons/components/letters/components/tables/letters/IconStatus.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
// Modals
import ModalFiles from "@/views/administration/views/letters/components//modal/ModalFiles.vue";
import ModalProcess from "@/views/commons/components/letters/components/modal/ModalProcess.vue";
import ModalReturn from "@/views/correspondence/views/letters/components/modal/ModalReturn.vue";
import ModalTracking from "@/views/correspondence/views/letters/components/modal/ModalTracking.vue";
import ModalDetails from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/details-modal/DetailsModal.vue";

export default {
  components: {
    IconStatus,
    ModalFiles,
    ModalProcess,
    ModalReturn,
    ModalTracking,
    ModalDetails,
    StatusAccount,
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      totalRows: 0,
      startPage: null,
      endPage: null,
      notificationAppModalOn: false,
      toPage: null,
      fields,
      isBusy: false,
      sortBy: "",
      sortDesc: true,
      filters: filters,

      type: null,

      // Modal status and data
      modalEditFirtRoundState: false,
      dataEditFirtRound: [],

      modalProcessState: false,
      dataProcess: [],

      modalReturnState: false,
      dataReturn: [],

      modalTrackingState: false,
      dataTracking: [],

      modalFileStatus: false,
      dataFile: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    typeTab() {
      switch (this.$route.name) {
        case "administration-letters-in-process":
          return 1; // In process
          break;
        case "administration-letters-pending":
          return 2; // Pending
          break;
        case "administration-letters-returned":
          return 3; // Returned
          break;
        case "administration-letters-completed":
          return 4; // Completed
          break;
      }
    },
    tabFields() {
      if (this.typeTab == 1 || this.typeTab == 3) {
        return this.fields;
      } else {
        return this.fields.filter((item) => item.key != "actions");
      }
    },
    filterProgram() {
      return this.filters[2].model;
    },
  },
  watch: {
    filterProgram(newProgram) {
      if (newProgram != null) {
        this.getAllAdvisors(newProgram);
      } else {
        this.filters[4].options = [{ id: 0, user_name: "ALL" }];
        this.filters[4].model = null;
      }
    },
  },
  created() {
    this.getAllAdministrators();
    // this.getAllAdvisors();
  },
  methods: {
    async getAllAdvisors(program) {
      this.filters[4].options = [];
      this.filters[4].model = null;

      let module = -1;
      if (program == 0) {
        module = 0;
      } else {
        switch (parseInt(program)) {
          case 2:
            module = 7;
            break;
          case 3:
            module = 6;
            break;
          case 4:
            module = 5;
            break;
        }
      }
      try {
        this.addPreloader();
        let params = {
          module_id: module,
          user_id: this.currentUser.user_id,
          role_id: this.currentUser.role_id,
        };
        const data = await LettersService.getAllAdvisors(params);
        this.filters[4].options = data;
        this.filters[4].options.unshift({ id: 0, user_name: "ALL" });
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },

    async getAllAdministrators() {
      try {
        const data = await LettersService.getAllAdministrators({
          roles: "[6]",
          type: "1",
        });
        let firstOption = {
          user_name: "All",
          id: 0,
        };
        let newData = data;
        newData.unshift(firstOption);
        this.filters[5].options = newData;
      } catch (error) {
        console.error(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with users!"
        );
      }
    },

    async myProvider(ctx) {
      try {
        let params = {
          name_text: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          program: this.filters[2].model,
          orderby: 8,
          order: ctx.sortDesc ? "desc" : "asc",
          advisorid: this.filters[4].model,
          roleid: this.currentUser.role_id,
          userid: this.currentUser.user_id,
          id_administrator: this.filters[5].model,
          typesend: this.filters[3].model ? this.filters[3].model : "",
          // Tab (1,2,3)
          status: 1,
          type: this.typeTab,
          perPage: ctx.perPage,
          page: ctx.currentPage,
        };
        if (this.typeTab == 4) {
          const { status, data } = await LettersService.getCompleted(params);
          if (status == 200) {
            this.startPage = data.from;
            this.totalRows = data.total;
            this.paginate.currentPage = data.current_page;
            this.paginate.perPage = data.per_page;
            this.endPage = data.last_page;
            this.toPage = data.to;
            return data.data || [];
          }
        } else {
          const { status, data } = await LettersService.getPending(params);
          if (status == 200) {
            this.startPage = data.from;
            this.totalRows = data.total;
            this.paginate.currentPage = data.current_page;
            this.paginate.perPage = data.per_page;
            this.endPage = data.last_page;
            this.toPage = data.to;
            return data.data || [];
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
      this.myProvider();
    },

    statusColor(item) {
      if (
        item == 3 ||
        item == 4 ||
        item == 6 ||
        item == 8 ||
        item == 9 ||
        item == 10
      ) {
        return "warning";
      } else if (item == 2 || item == 5 || item == 1) {
        return "primary";
      } else if (item == 7) {
        return "success";
      } else {
        return "";
      }
    },
    openModalEditFirtRound(data) {
      this.addPreloader();
      this.dataEditFirtRound = data;
      this.modalEditFirtRoundState = true;
    },
    async openModalFiles(data) {
      this.dataFile = { ...data, ...{ typeTab: this.typeTab } };
      this.modalFileStatus = true;
    },
    openModalTracking(data) {
      this.dataTracking = {
        id: data.id,
        name_client: data.client_name,
        account: data.account,
        type: 2,
      };
      this.modalTrackingState = true;
    },
    openModalProcess(data, type) {
      this.dataProcess = data;
      this.type = type;
      this.modalProcessState = true;
    },
    openModalReturn(data) {
      this.dataReturn = {
        id: data.id,
        name_client: data.client_name,
        account: data.account,
        type: 1,
      };
      this.modalReturnState = true;
    },
  },
};
</script>

<style scoped></style>
