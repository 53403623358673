export default [
      {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        },
        cols: 6,
      },
      {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        },
        cols: 6,
      },
      {
         type: "select",
         margin: true,
         showLabel: true,
         label: "Program",
         model: null,
         options: [
            {value: 0, label: "All"},
            {value: 2, label: "Boost Credit"},         
            {value: 3, label: "Credit Experts"},
            {value: 4, label: "Debt Solution"},    
         ],
         reduce: "value",
         selectText: "label",
         cols: 6,
       },
       {
         type: "select",
         margin: true,
         showLabel: true,
         label: "Type",
         model: null,
         options: [
            {value: 0, label: "All"},
            {value: 1, label: "REGULAR"},         
            {value: 2, label: "CERTIFIED"},       
         ],
         reduce: "value",
         selectText: "label",
         cols: 6,
       },
      {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Advisor",
        model: null,
        options: [],
        reduce: "id",
        selectText: "user_name",
        cols: 12,
      },
      {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Administrator",
        model: null,
        options: [],
        reduce: "id",
        selectText: "user_name",
        cols: 12,
      },
    ];
    
